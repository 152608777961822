import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "@utils/seo"

import Link from "@atoms/link"
import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import CardPost from "@molecules/cards/post"
import NavBlog from "@molecules/navBlog"
import Pagination from "@molecules/pagination"
import Date from "@utils/date"
import Layout from "@utils/layout"

const BlogPage = ({ data, pageContext }) => {
  const { posts } = data.body
  const post = data.header

  return (
    <Layout>
      <SEO title="Blog" />
      <div data-datocms-noindex>
        <NavBlog />
        {pageContext.currentPage === 1 && (
          <Section lg xlTop>
            <Link to={`/${post.nodes[0].slug}`}>
              <Container>
                <Flex rowReverse alignCenter>
                  <Column half>
                    <Image>
                      {post.nodes[0].featuredImage && (
                        <GatsbyImage
                          image={post.nodes[0].featuredImage.gatsbyImageData}
                          alt={post.nodes[0].featuredImage.alt}
                          title={post.nodes[0].featuredImage.title}
                        />
                      )}
                    </Image>
                  </Column>
                  <Column half>
                    <PostDate html="h6" level="sh" strong lightGray>
                      <Date date={post.nodes[0].meta.firstPublishedAt} />
                    </PostDate>
                    <Title html="h1" level="h1">
                      {post.nodes[0].title}
                    </Title>
                    <SubContent>
                      <Category html="h6" level="sh" strong aqua>
                        {post.nodes[0].category.title}
                      </Category>
                      <Heading html="h6" level="sh" strong lightGray>
                        {post.nodes[0].author.name}
                      </Heading>
                    </SubContent>
                  </Column>
                </Flex>
              </Container>
            </Link>
          </Section>
        )}

        <Section light lg mdTop>
          <Container xxs>
            <Heading html="h6" level="sh" strong lightGray>
              Recent Posts
            </Heading>
          </Container>
          <Container>
            <Flex>
              {posts.map((post, index) => (
                <PostColumn cards key={index}>
                  <CardPost post={post} />
                </PostColumn>
              ))}
            </Flex>
          </Container>
          <Container smTop>
            <Pagination pageContext={pageContext} />
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default BlogPage

export const fetchBlog = graphql`
  query fetchBlog($skip: Int!, $limit: Int!) {
    header: allDatoCmsPost(
      limit: 1
      sort: { fields: date, order: DESC }
      filter: { meta: { status: { in: ["published", "updated"] } } }
    ) {
      nodes {
        author {
          name
          avatar {
            alt
            title
            gatsbyImageData(
              width: 56
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
            alt
          }
        }
        category {
          title
          slug
        }
        content
        featuredImage {
          alt
          title
          gatsbyImageData(
            width: 800
            layout: CONSTRAINED
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
          )
          alt
        }
        id
        slug
        title
        date
        meta {
          firstPublishedAt
        }
      }
    }
    body: allDatoCmsPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: { meta: { status: { in: ["published", "updated"] } } }
    ) {
      posts: nodes {
        author {
          name
          avatar {
            alt
            title
            gatsbyImageData(
              width: 56
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
        }
        category {
          title
          slug
        }
        content
        featuredImage {
          alt
          title
          gatsbyImageData(
            width: 480
            layout: CONSTRAINED
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
          )
        }
        id
        slug
        title
        date
        meta {
          firstPublishedAt
        }
      }
    }
  }
`

const PostColumn = styled(Column)`
  padding-bottom: var(--spacing--sm);
`

const Image = styled.div`
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  width: 100%;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((8 / 17) * 100%);
  }

  .gatsby-image-wrapper {
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const SubContent = styled.div`
  display: flex;
`

const PostDate = styled(Heading)`
  margin-bottom: 1rem;
`

const Title = styled(Heading)`
  padding-bottom: 1.5rem;
`

const Category = styled(Heading)`
  padding-right: 1rem;
`
